import React from "react";
import { AtSymbol } from "./AtSymbol";
import { colorForGrade, tidyGrades } from "../utils/climb-utils";

const Difficulty = ({
  averageDifficulty,
  angle,
}: {
  averageDifficulty: number;
  angle: number;
}) => {
  const difficultyIndex = Math.floor(averageDifficulty);
  const grade = tidyGrades[difficultyIndex];

  const validGrade = (grade || "").indexOf("V") === 0;

  const vGrade = validGrade ? parseInt(grade.slice(1)) : NaN;

  return (
    <div className="ml-2 mr-4 flex items-center">
      <p
        className={`mr-1 font-semibold text-l opacity-60 ${colorForGrade(
          vGrade
        )}`}
      >
        V{vGrade}
      </p>
      <AtSymbol className="opacity-40" />
      <p className="ml-1 font-semibold text-l opacity-60">{angle}°</p>
    </div>
  );
};

export default Difficulty;
