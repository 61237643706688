import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { QueryCache, QueryClient, QueryClientProvider } from "react-query";

import ClimbList from "./ClimbList";
import ClimbView from "./ClimbView";
import { QueryParamProvider } from "use-query-params";

export const queryClient = new QueryClient({
  queryCache: new QueryCache(),
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Switch>
            <Route exact path="/">
              <ClimbList />
            </Route>
            <Route path="/climb/:climbId">
              <ClimbView />
            </Route>
          </Switch>
        </QueryParamProvider>
      </Router>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}

export default App;
