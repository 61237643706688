import React, { useCallback } from "react";
import { Board } from "./components/Board";
import { useHistory, useParams } from "react-router-dom";
import { useEffect } from "react";
import { mapSeries } from "bluebird";

import Difficulty from "./components/Difficulty";
import { findPreferredAngle } from "./utils/climb-utils";
import { getBluetoothMessages } from "./utils/bluetoothUtils";
import { useClimb, useNeighboringClimbs } from "./hooks/useClimb";
import { useSwipeable } from "react-swipeable";
import { useStore } from "./hooks/useStore";
import { X } from "./components/icons";

const LeftChevron = ({ className, ...props }: any) => (
  <svg
    className={`${className} w-8 h-8`}
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
      clipRule="evenodd"
    />
  </svg>
);

const Light = ({ ...props }: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="currentColor"
    {...props}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M9 21c0 .5.4 1 1 1h4c.6 0 1-.5 1-1v-1H9v1zm3-19C8.1 2 5 5.1 5 9c0 2.4 1.2 4.5 3 5.7V17c0 .5.4 1 1 1h6c.6 0 1-.5 1-1v-2.3c1.8-1.3 3-3.4 3-5.7 0-3.9-3.1-7-7-7z" />
  </svg>
);

function ClimbView() {
  const store = useStore();
  const history = useHistory();

  const { climbId } = useParams();
  const { data: climb } = useClimb(climbId);

  const neighboaringClimbs = useNeighboringClimbs(climbId);

  const prevClimb = useCallback(() => {
    if (neighboaringClimbs.before?.uuid) {
      history.replace(`/climb/${neighboaringClimbs.before.uuid}`);
    }
  }, [history, neighboaringClimbs]);

  const nextClimb = useCallback(() => {
    if (neighboaringClimbs.after?.uuid) {
      history.replace(`/climb/${neighboaringClimbs.after.uuid}`);
    }
  }, [history, neighboaringClimbs]);

  useEffect(() => {
    if (climb) {
      document.title = `${climb.name} by @${climb.setter_username} - Kilterboard`;
    }
  }, [climb]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        prevClimb();
      } else if (event.key === "ArrowRight") {
        nextClimb();
      }
    };

    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, [history, prevClimb, nextClimb]);

  useEffect(() => {
    if (store.bluetoothCharacteristic) {
      sendClimbToWall(store.bluetoothCharacteristic, climb);
    }
  }, [store.bluetoothCharacteristic, climb]);

  const handlers = useSwipeable({
    onSwipedLeft: nextClimb,
    onSwipedRight: prevClimb,
  });

  const preferredAngle = climb && findPreferredAngle(climb);

  return (
    <div className="p-0 sm:p-4">
      <div className="page-card sm:overflow-hidden">
        <div className="flex items-stretch justify-between p-0 ">
          <button
            onClick={() => history.goBack()}
            className="flex items-center p-2 w-10 sm:rounded-tl-xl hover:bg-gray-500 cursor-pointer"
          >
            <LeftChevron />
          </button>

          <span className="flex justify-around flex-col flex-1 p-2 pl-1 truncate">
            <p className="text-lg sm:text-2xl font-semibold subpixel-antialiased text-left mr-2 truncate">
              {climb ? climb.name : ""}
            </p>
            <p className="items-center hidden sm:flex opacity-80">
              by{" "}
              <span className="cursor-pointer ml-1 hover:opacity-100">
                @{climb ? climb.setter_username : ""}
              </span>
            </p>
          </span>

          <div className="flex">
            {!store.connected && (
              <span
                className="cursor-pointer ml-2 mr-4 mb-1 flex items-center"
                onClick={() => {
                  connectToBluetooth()
                    .then((characteristic) => {
                      store.connect(characteristic);
                    })
                    .catch((err) => alert(err));
                }}
              >
                <Light />
              </span>
            )}

            <Difficulty
              averageDifficulty={preferredAngle?.difficulty_average}
              angle={preferredAngle?.angle}
            />
          </div>
        </div>
        {store.connected && (
          <div className="p-1 bg-green-500 text-green-900 flex justify-between items-center">
            <span>
              Connected to <b>Fake Kilter</b>
            </span>
            <span
              className="cursor-pointer font-black"
              onClick={() => store.disconnect()}
            >
              <X className="w-4 h-4" />
            </span>
          </div>
        )}
        {climb && (
          <div {...handlers}>
            <Board
              climb={climb}
              className="p-2 pt-5 bg-gray-900"
              style={{ maxHeight: 750 }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * Attempt to pair with the wall via bluetooth, returning
 * the bluetooth characteristic if successful.
 */
const connectToBluetooth = async () => {
  if (!("bluetooth" in navigator)) {
    return;
  }

  return (navigator as any).bluetooth
    .requestDevice({
      filters: [
        {
          services: ["4488b571-7806-4df6-bcff-a2897e4953ff"],
        },
      ],
      optionalServices: ["6e400001-b5a3-f393-e0a9-e50e24dcca9e"],
    })
    .then((device) => {
      console.log("> Found " + device.name);
      console.log("Connecting to GATT Server...");
      return device.gatt.connect();
    })
    .then((server) =>
      server.getPrimaryService("6e400001-b5a3-f393-e0a9-e50e24dcca9e")
    )
    .then((service) =>
      service.getCharacteristic("6e400002-b5a3-f393-e0a9-e50e24dcca9e")
    );
};

const sendClimbToWall = (characteristic: any, climb: any) => {
  return mapSeries(getBluetoothMessages(climb), (msg) =>
    characteristic.writeValue(msg)
  );
};

export default ClimbView;
