import * as R from "ramda";
import grades from "./grades.json";

import { createClient } from "@supabase/supabase-js";

export const colorForGrade = (n: number) =>
  n < 3 ? "text-green-300" : n < 7 ? "text-yellow-300" : "text-red-300";

export const findPreferredAngle = (climb) =>
  climb.climb_stats.reduce(R.maxBy(R.prop("ascensionist_count") as any));

export const tidyGrades = R.fromPairs(
  grades.map(({ difficulty, boulder_name }) => [
    difficulty,
    boulder_name.split("/")[1],
  ])
);

const supabaseUrl = "https://drmzubrwofxyzyhicvvx.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYzMTI3NDA1MiwiZXhwIjoxOTQ2ODUwMDUyfQ.vBZ8uBgVI3Wc9RaJ2STinaVnd0dY2HHyK42YkqBxUR0";
const supabase = createClient(supabaseUrl, supabaseKey);

export const PAGE_SIZE = 20;

export const loadTopClimbs_supabase = async (offset: number = 0) => {
  const nextOffset = offset + PAGE_SIZE;

  const response = await supabase
    .from("climbs")
    .select("*", { count: "planned" })
    .order("total_ascents", { ascending: false })
    .range(offset, nextOffset - 1);

  return {
    ...response,
    offset,
    nextOffset,
  };
};

export const loadClimb_supabase = async (climbId: string) => {
  return await supabase.from("climbs").select("*").eq("uuid", climbId).single();
};
