import React from "react";

const kilterNaturalHeight = 1170;
const kilterNaturalWidth = 1080;

const ROW_COUNT = 19;
// const COL_COUNT = 17;

const originX = 58.6;
const originY = 30.8;
const stepSize = 60;
const circleRadius = 29.51;

const startColor = "#00dd00";
const middleColor = "#00ffff";
const finishColor = "#ff00ff";
const footOnlyColor = "#ffa500";

// const ROLE_ID_MAP = {
//   12: "START",
//   13: "MIDDLE",
//   14: "FINISH",
//   15: "FEET-ONLY",
// };

const colorForType = (type) =>
  ({
    START: startColor,
    MIDDLE: middleColor,
    FINISH: finishColor,
    "FEET-ONLY": footOnlyColor,
  }[type]);

const bottomOriginY = originY + (ROW_COUNT - 1) * stepSize;

const getHoldXPos = (hold) => (hold.x / 2) * stepSize + originX - stepSize;

const getHoldYPos = (hold) =>
  bottomOriginY -
  (Math.floor(hold.y / 2) + 1) * stepSize +
  (isScrewOn(hold) ? stepSize / 2 : 0) + // foot holds exist between the hand holds
  (hold.y === 0 ? (hold.x % 2 === 0 ? stepSize / 2 : stepSize) : 0); // the kick boards foot holds are below the hand holds

const isScrewOn = (o) => !isBoltOn(o);

const isBoltOn = ({ x, y }) => x % 2 === 0 && y % 2 === 1;

const makeCircle = ({ xPos, yPos, color, radius }) => (
  <circle
    key={`${xPos}_${yPos}`}
    cx={xPos}
    cy={yPos}
    r={radius}
    stroke={color}
    strokeWidth="4"
    fill="transparent"
    style={{ cursor: "pointer" }}
  />
);

export const Board = ({ climb, ...extraProps }: any) => {
  const holds = climb.placements;

  return (
    <svg
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${kilterNaturalWidth} ${kilterNaturalHeight}`}
      {...extraProps}
    >
      <image
        href="/kilter/10_9_1.png"
        height={kilterNaturalHeight}
        width={kilterNaturalWidth}
      />
      <image
        href="/kilter/10_9_20.png"
        height={kilterNaturalHeight}
        width={kilterNaturalWidth}
      />

      {holds.map((hold) =>
        makeCircle({
          xPos: getHoldXPos(hold),
          yPos: getHoldYPos(hold),
          color: colorForType(hold.type),
          radius: isBoltOn(hold) ? circleRadius : circleRadius / 2,
        })
      )}
    </svg>
  );
};
