import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import { AtSymbol } from "./components/AtSymbol";
import {
  colorForGrade,
  findPreferredAngle,
  loadTopClimbs_supabase,
  PAGE_SIZE,
  tidyGrades,
} from "./utils/climb-utils";

import { useInfiniteQuery } from "react-query";
import { useState } from "react";
import { ChevronRight, UserGroup } from "./components/icons";

const ClimbList = () => {
  useEffect(() => {
    document.title = "Top Climbs - Kilterboard";
  }, []);

  const [atTop, setAtTop] = useState<boolean>(true);

  const {
    data: infiniteData,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    "climbs",
    ({ pageParam = 0 }) => loadTopClimbs_supabase(pageParam),
    {
      // never garbage collect this query, we use it in the swiping view
      cacheTime: Number.POSITIVE_INFINITY,
      getNextPageParam: (lastPage) => lastPage.nextOffset,
    }
  );

  const climbs = infiniteData?.pages.flatMap((page) => page.data);
  const count = [...(infiniteData?.pages || [])]
    .reverse()
    .find((page) => page.count)?.count;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries
        .map(({ isIntersecting }: any) => isIntersecting)
        .reduce((a, b) => a || b, false);

      setAtTop(isIntersecting);
    });

    const intersectionElement = document.querySelector("#intersectionElement");

    if (!intersectionElement) {
      console.log("no intersection element provided");
      return;
    }

    observer.observe(intersectionElement!);

    return () => observer.disconnect();
  }, [setAtTop]);

  return (
    <>
      <div className="p-0 sm:p-4 sm:pb-12">
        <span id="intersectionElement" className="absolute t-0" />
        <div className="page-card divide-y divide-gray-500 pt-16 sm:pt-0">
          <div
            key="header"
            className={`fixed sm:static bg-gray-600 top-0 left-0 right-0 z-10 sm:rounded-t-xl ${
              atTop ? "" : "shadow-xl"
            } sm:shadow-none`}
          >
            <p className="text-xl opacity-80 p-4">
              Climbs{" "}
              {count && (
                <span className="pl-2 text-sm opacity-60 font-semibold">
                  {new Intl.NumberFormat().format(count)}
                </span>
              )}
            </p>
          </div>
          <div className="divide-y divide-gray-500">
            {(climbs || []).map((climb) => (
              <ClimbListItem key={climb.uuid} climb={climb} />
            ))}
            {isLoading
              ? new Array(PAGE_SIZE)
                  .fill(1)
                  .map((_, i) => (
                    <ClimbListPlaceholder key={`placeholder-${i}`} />
                  ))
              : undefined}
            {!isLoading ? (
              <button
                className="p-4 w-full hover:bg-gray-500 rounded-b-xl"
                onClick={() => fetchNextPage()}
              >
                {!isFetchingNextPage ? "Load more" : "Loading..."}
              </button>
            ) : undefined}
          </div>
        </div>
      </div>
    </>
  );
};

const ClimbListItem = ({ climb }: any) => {
  const preferedAngle = findPreferredAngle(climb);

  return (
    <Link
      to={`/climb/${climb.uuid}`}
      className="hover:bg-gray-500 cursor-pointer p-2 flex items-center justify-between rounded-t-none"
    >
      <div className="flex-1 ml-2 mr-2 truncate">
        <p className="font-semibold text-l truncate">{climb.name}</p>
        <span className="opacity-80">@{climb.setter_username}</span>
      </div>

      <div className="ml-2 mr-4 flex items-center">
        <p
          className={`mr-1 font-semibold text-l opacity-60 ${colorForGrade(
            parseInt(
              tidyGrades[Math.floor(preferedAngle.difficulty_average)].slice(1)
            )
          )}`}
        >
          {tidyGrades[Math.floor(preferedAngle.difficulty_average)]}
        </p>
        <AtSymbol className="opacity-40" />
        <p className="ml-1 font-semibold text-l opacity-60">
          {preferedAngle.angle}°
        </p>
      </div>

      <div className="flex opacity-70 space-x-2 mx-2" title="Total sends">
        <UserGroup className="w-3 h-3 opacity-50" />
        <span>{new Intl.NumberFormat().format(climb.total_ascents)}</span>
      </div>

      <ChevronRight className="hidden sm:block" />
    </Link>
  );
};

const ClimbListPlaceholder = () => (
  <div className="flex justify-between animate-pulse">
    <div className="space-x-4 flex flex-col m-4">
      <div className="w-32 sm:w-52 h-3 rounded bg-gray-400 m-1"></div>
      <div className="w-16 sm:w-32 h-2 rounded bg-gray-400 m-1"></div>
    </div>
    <div className="space-x-2 flex items-center m-4">
      <div className="w-12 sm:w-24 h-2 rounded bg-gray-400 m-1"></div>
      <div className="w-16 sm:w-32 h-2 rounded bg-gray-400 m-1"></div>
    </div>
  </div>
);

export default ClimbList;
