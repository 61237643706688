import { splitEvery } from "ramda";

interface HasPlacements {
  placements: Array<{
    x: number;
    y: number;
    type: "FEET-ONLY" | "START" | "MIDDLE" | "FINISH";
		
		// This is used in the bluetooth messages when referencing position.
		// not exactly sure why this is needed considering
		// we have x and y already. We should be able to compute
		// this rather than using `holes` `hodes` and `placements`.
		ledPosition: number;
  }>;
}

const colors = {
	"FEET-ONLY": 224,
	"START": 48,
	"MIDDLE": 60,
	"FINISH": 204
}

/**
 * Compute the messages that need to be sent to the kilter
 * board to show a climb.
 *
 * This is a port of the `prepBytesV2` algorithm
 *
 * @param climb
 */
export const getBluetoothMessages = (climb: HasPlacements) => {
  const placements = [
    80, // constant, "changed command to 'P'"
		
		...climb.placements.flatMap(placement => {
			return [
				placement.ledPosition & 255,
				colors[placement.type] + ((placement.ledPosition & 768) >> 8),
			];
		})
  ];
  const header = [placements.length, checksum(placements)];

  return splitMessages([1, ...header, 2, ...placements, 3]);
};

/**
 * The kilter board only supports messages of 20 bytes
 * at a time. This method splits a full message into parts
 * of 20 bytes
 *
 * @param buffer
 */
export const splitMessages = (buffer: number[]): Array<Uint8Array> =>
  splitEvery(20, buffer).map((arr) => new Uint8Array(arr));

/**
 * The second byte of the header, ported from the Java algorithm.
 * A checksum should only contain the placement data.
 * 
 * @param list 
 */
export const checksum = (list: number[]): number => {
	let i = 0;
	
	list.forEach(n => {
		i = (i + n) & 255;
	})

	return (~i) & 255;
};

export {};
