import create from "zustand";

export const useStore = create<{
  connected: boolean;
  bluetoothCharacteristic: any | null;
  connect: (bluetoothCharacteristic: any) => void;
  disconnect: () => void;
}>((set) => ({
  connected: false,
  bluetoothCharacteristic: null,
  connect: (bluetoothCharacteristic) =>
    set((state) => ({ connected: true, bluetoothCharacteristic })),
  disconnect: () =>
    set((state) => {
      if (state.bluetoothCharacteristic) {
        state.bluetoothCharacteristic?.service?.device?.gatt?.disconnect();
      }

      return { connected: false, bluetoothCharacteristic: null };
    }),
}));
