import { InfiniteData, useQuery } from "react-query";
import { queryClient } from "../App";
import {
  loadClimb_supabase,
  loadTopClimbs_supabase,
} from "../utils/climb-utils";

type QueryType = Awaited<ReturnType<typeof loadTopClimbs_supabase>>;

export const useClimb = (climbId: string) => {
  return useQuery<any>(
    `supabaseclimb-${climbId}`,
    async () => (await loadClimb_supabase(climbId)).data,
    {
      staleTime: 60 * 60 * 1000, // one hour
      initialData: () => {
        const cachedData = queryClient.getQueryData<InfiniteData<QueryType>>(
          "climbs"
        );

        return cachedData?.pages
          .flatMap(({ data }) => data)
          .find(({ uuid }) => uuid === climbId);
      },
    }
  );
};

/**
 * Given a climbId, return the two neighbouring climbs for the
 * search that has been performed.
 *
 * It doesn't this by looking at the `react-query` cache, which
 * means reloading the page will cause the neighbouring climbs to
 * not be returned.
 *
 * TODO: Perhaps we can encode the search as part of the url and then
 * fetch this on load?
 */
export const useNeighboringClimbs = (
  climbId: string
): { before: any; after: any } => {
  const cachedData = queryClient.getQueryData<InfiniteData<QueryType>>(
    "climbs"
  );

  const climbs = cachedData?.pages.flatMap(({ data }) => data) || [];
  const climbIndex = climbs.findIndex(({ uuid }) => uuid === climbId);

  if (climbIndex === undefined) {
    return {
      before: undefined,
      after: undefined,
    };
  }

  const atStart = climbIndex === 0;

  if (atStart) {
    return {
      before: undefined,
      after: climbs[1],
    };
  }

  const atEnd = climbIndex === climbs.length - 1;

  if (atEnd) {
    // TODO: look at triggering a load of the next page
    return {
      before: climbs[climbIndex - 1],
      after: undefined,
    };
  }

  const [before, , after] = climbs.slice(climbIndex - 1, climbIndex + 2);

  return {
    before,
    after,
  };
};
